<template>
    <LiefengContent>
        <template v-slot:title>
            中奖名单
            <span class="eye" @click.stop="changeEye">
                <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
            </span>
        </template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true">
                <FormItem><Input :maxlength="20" v-model.trim="userName" placeholder="按姓名搜索" style="max-width: 160px; float: left; margin-right: 10px" /></FormItem>
                <FormItem><Input :maxlength="20" v-model.trim="phone" placeholder="按手机号搜索" style="max-width: 160px; float: left" /></FormItem>

                <Button type="primary" style="color: #fff; margin: 0 10px" @click="search" icon="ios-search">搜索</Button>
                <Button type="info" style="color: #fff;margin: 0 10px" @click="reset" icon="ios-refresh" >重置</Button>
                <Button @click="exportExcel" type="error" icon="ios-cloud-download-outline" style="color: #fff;margin: 0 10px">导出</Button>
                <Button type="primary" @click="pushByWinner" :loading="pushLoading" style="color: #fff;margin: 0 10px">推送中奖用户</Button>
                <Button type="primary" @click="calculationQuestionWinner" :loading="calculaLoading" style="color: #fff;margin: 0 10px">重新生成名单</Button>
                <Button @click="clickBack" type="success">返回</Button>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :total="total"
                :curPage="currentPage"
                :pagesizeOpts="[20, 30, 50, 100]"
                @hadlePageSize="pageChange"
                :loading="loading"
                :fixTable="true"
                :pageSize="pageSize"
                :showIndex="false"
            ></LiefengTable>
            <!-- 发布弹窗 -->
            <LiefengModal title="发布" width="500px" height="320px" :value="publishStatus" @input="publishStatusFn">
                <template v-slot:contentarea>
                    <Form ref="publishForm" :model="publishData" :label-width="100" :label-colon="true" :rules="publishValidator">
                        <FormItem label="领奖状态" prop="prizeStatus">
                            <RadioGroup v-model="publishData.prizeStatus">
                                <Radio label="2" border>领取奖品</Radio>
                                <Radio label="3" border>弃奖</Radio>
                            </RadioGroup>
                        </FormItem>
                        <FormItem label="领奖方式" prop="prizeUserType">
                            <RadioGroup v-model="publishData.prizeUserType">
                                <Radio label="1" border>本人领取</Radio>
                                <Radio label="2" border>家人代领</Radio>
                                <Radio label="3" border>朋友代领</Radio>
                            </RadioGroup>
                        </FormItem>
                        <FormItem label="领奖人" prop="prizeUser" v-if="publishData.prizeUserType != 1">
                            <Input v-model.trim="publishData.prizeUser" :maxlength="20"></Input>
                        </FormItem>
                        <FormItem label="备注">
                            <Input v-model.trim="publishData.remark" type="textarea" show-word-limit :autosize="{ minRows: 2, maxRows: 5 }" :maxlength="400"></Input>
                        </FormItem>
                    </Form>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="publishStatusFn(false)" style="margin-right: 10px">取消</Button>
                    <Button type="primary" @click="publishSave">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/answerwinning')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
export default {
    data() {
        return {
            // 脱敏
            hasSensitive: true,
            //搜索框内容
            userName: "",
            phone: "",
            loading: false,
            //title
            //表头列表
            talbeColumns: [
                {
                    title: "排行",
                    key: "userRanking",
                    width: 140,
                    align: "center",
                },
                {
                    title: "街道",
                    key: "streetName",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "社区",
                    key: "communityName",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "姓名",
                    key: "userName",
                    width: 140,
                    align: "center",
                    render: (h, params) => {
                        return h("div",{},params.row.answerUserVo && params.row.answerUserVo.userName ? params.row.answerUserVo.userName : '')
                    },
                },
                {
                    title: "联系电话",
                    key: "mobile",
                    width: 140,
                    align: "center",
                },
                {
                    title: "现住地址",
                    key: "addr",
                    width: 200,
                    align: "center",
                },
                {
                    title: "分数",
                    key: "score",
                    width: 200,
                    align: "center",
                },
                {
                    title: "用时",
                    key: "timeTotal",
                    width: 140,
                    align: "center"
                },
                
                {
                    title: "领取状态",
                    key: "prizeStatus",
                    width: 140,
                    align: "center",
                },
                {
                    title: "领取时间",
                    key: "prizeTime",
                    width: 200,
                    align: "center",
                },
                
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    fixed: "right",
                    width: 120,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                        disabled: params.row.prizeStatus == "未领取" ? false : true,
                                    },
                                    style: {},
                                    on: {
                                        click: () => {
                                            this.publish(params.row)
                                        },
                                    },
                                },
                                params.row.prizeStatus == "未领取" ? "发放" : "已处理"
                            ),
                        ])
                    },
                },
            ],
            tableData: [],
            total: 0,
            currentPage: 1,
            pageSize: 20,

            //发布
            publishStatus: false,
            prizeUser: "",
            publishId: "",
            publishData: {},
            publishValidator: {
                prizeStatus: [{ required: true, message: "请选择领奖状态", trigger: "blur" }],
                prizeUserType: [{ required: true, message: "请选择领奖方式", trigger: "blur" }],
                prizeUser: [{ required: true, message: "请添加领奖人", trigger: "blur" }],
            },
            pushLoading: false,
            calculaLoading: false,
        }
    },
    methods: {
        // 返回
        clickBack(){
            var index = parent.layer.getFrameIndex(window.name) 
            if (index) {
                //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.$router.push('/newanswerindex?menuId=' + this.$route.query.menuId)
            }
           
        },
        //搜索
        search() {
            this.pageChange({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        //重置
        async reset() {
            this.userName = ""
            this.phone = ""
            this.currentPage = 1
            this.pageSize = 20
            await this.pageChange({
                page: this.currentPage,
                pageSize: this.pageSize,
            })
        },
        //发放
        publish(row) {
            this.prizeUser = row.userName
            this.publishId = row.id
            this.publishStatus = true
        },
        publishStatusFn(status) {
            this.publishStatus = status
        },
        publishSave() {
            this.$refs.publishForm.validate(status => {
                if (status) {
                    this.$post("/voteapi/api/pc/componentQuestion/redeem", {
                        winnerUserId: this.publishId,
                        prizeStatus: this.publishData.prizeStatus,
                        prizeUser: this.publishData.prizeUserType == 1 ? this.prizeUser : this.publishData.prizeUser,
                        prizeUserType: this.publishData.prizeUserType,
                        staffName: parent.vue.loginInfo.userinfo.realName,
                        remark: this.publishData.remark,
                    })
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "发放成功",
                                })
                                this.publishStatus = false
                                this.pageChange({
                                    page: this.currentPage,
                                    pageSize: this.pageSize,
                                })
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            this.$Message.error({
                                background: true,
                                content: "发放失败",
                            })
                        })
                }
            })
        },
        //导出名单
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
                onOk: () => {
                    let tHeader = ["排行","街道", "社区", "姓名", "联系电话", "分数", "用时", "领取状态","领取时间"]
                    let filterVal = ["userRanking", "streetName", "communityName", "userName", "mobile", "score", "timeTotal", "prizeStatus", "prizeTime"]
                    this.$core.exportExcel(tHeader, filterVal, this.tableData, "中奖名单列表")
                },
            })
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => v[j]))
        },
        //分页器
        async pageChange(data) {
            this.loading = true
            await this.$get("/info/api/pc/information/answer/selectQuestionWinnerPage", {
                userName: this.userName,
                mobile: this.phone,
                businessType: "0",
                businessCode: this.$route.query.id,
                oemCode: parent.vue.oemInfo.oemCode,
                page: data.page,
                pageSize: data.pageSize,
                hasSensitive: this.hasSensitive,
            }).then(res => {
                if (res.code == 200) {
                    res.dataList.map((item, index, arr) => {
                        item.prizeStatus = item.prizeStatus == "1" ? "未领取" : item.prizeStatus == "2" ? "已领取" : "弃奖"
                        if (item.prizeTime) {
                            item.prizeTime = this.$core.formatDate(new Date(item.prizeTime), "yyyy-MM-dd hh:mm:ss")
                        }
                        if(item.answerUserVo.timeTotal){
                            let time = item.answerUserVo.timeTotal
                            let h1 = Math.floor(time / 3600)
                            let m = Math.floor((time / 60) % 60)
                            let s = Math.floor(time % 60)
                            item.timeTotal = h1 + "时" + m + "分" + s + "秒"
                        }
                        item.score = item.answerUserVo.score
                    })
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                    this.pageSize = res.pageSize
                    this.loading = false
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                    this.loading = false
                    return
                }
            })
        },
        changeEye() {
            this.hasSensitive = !this.hasSensitive
            this.pageChange({
                page: this.currentPage,
                pageSize: this.pageSize,
            })
        },
        // 推送按钮
        pushByWinner() {
            this.$Message.loading({
                content: "正在推送，请稍等",
            })
            this.pushLoading = true
            this.$post("/info/api/pc/information/answer/pushByWinner", {
                businessType: "0",
                businessCode: this.$route.query.id,
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                this.pushLoading = false
                this.$Message.destroy()
                if (res.code && res.code == 200) {
                    this.$Message.success({
                        content: "操作成功",
                        background: true,
                    })
                    return
                } else {
                    this.$Message.error({
                        content: "操作失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 重新生成按钮
        async calculationQuestionWinner() {
            this.$Message.loading({
                content: "正在重新生成，请稍等",
            })
            this.calculaLoading = true
            await this.$post("/gateway/api/sycomponent/pc/answerWinner/calculationQuestionWinner", {
                businessType: "0",
                businessCode: this.$route.query.id,
            }).then(async res => {
                this.calculaLoading = false
                this.$Message.destroy()
                if (res.code && res.code == 200) {
                    this.$Message.success({
                        content: "操作成功",
                        background: true,
                    })
                    await this.reset()
                    return
                } else {
                    this.$Message.error({
                        content: "操作失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },

    created() {
        this.pageChange({
            page: this.currentPage,
            pageSize: this.pageSize,
        })
    },
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
    },
}
</script>
    
<style scoped lang='less'>
.eye {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    margin: 0 15px 0;
}
</style>